import React, { useContext, useEffect, useState } from "react";
import css from "./LoungeGlass.module.css";

function airportLoungeGlass() {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <div>
      <a
        className={css.seeLiveProject1}
        target="_blank"
        rel="noopener noreferrer"
        href="https://clever-mcnulty-210eb1.netlify.com"
      >
        Project Detail
      </a>
      <br></br>
      <a
        className={css.sourceCodeProject1}
        target="_blank"
        rel="noopener noreferrer"
        href="https://github.com/impactyogi/SOCBlockGame"
      >
        Project Gallery
      </a>
    </div>
  );
}

export default airportLoungeGlass;

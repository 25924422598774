import React, { useContext, useEffect, useState } from "react"
import css from "./Biofuels.module.css"

function Biofuels() {
  const [isDesktop, setIsDesktop] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true)
      setIsMobile(false)
    } else {
      setIsMobile(true)
      setIsDesktop(false)
    }
  }, [])

  return (
    <div>
      <a
        className={css.seeLiveProject4}
        target="_blank"
        rel="noopener noreferrer"
        href="https://upbeat-jones-663db2.netlify.app/"
      >
        Project Detail
      </a>
      <br></br>
      <a
        className={css.sourceCodeProject4}
        target="_blank"
        rel="noopener noreferrer"
        href="https://github.com/impactyogi/quizApp"
      >
        Project Gallery
      </a>
    </div>
  )
}

export default Biofuels

import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import Particles from "../components/Particles/index"
import TechProjectsPage from "../components/TechProjectsPage/index"
import ManufacturePage from "../components/ManfacturePage/index.js"

const Manufacture = () => (
  <Layout>
    <SEO title="Manufacture" />
    <Link to="/">Home</Link>
    {/* <Particles /> */}
    <TechProjectsPage />
    <ManufacturePage />
    <ul>
      Some tech projects that have reallly inspired me - please note further
      details on all projects coming soon pending site completion
    </ul>
    <li>Week 8 Project</li>
    <li>Final Project</li>
    {/* <li>Game making - the process</li>
    <li>Personal Business Websites</li> */}
  </Layout>
)

export default Manufacture

import React, { useContext, useEffect, useState } from "react";
import css from "./PpysBBq.module.css";

function PpysBBq() {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <div>
      <a
        className={css.seeLiveProject6}
        target="_blank"
        rel="noopener noreferrer"
        href="https://determined-goldstine-4bb07c.netlify.com"
      >
        Project Detail
      </a>
      <br></br>
      <a
        className={css.sourceCodeProject6}
        target="_blank"
        rel="noopener noreferrer"
        href="https://github.com/impactyogi/week5session23"
      >
        Project Gallery
      </a>
    </div>
  );
}

export default PpysBBq;

import React from "react"
import css from "../HexagonProjects/HexagonProjects.module.css"
import LoungeGlass from "../Projects/LoungeGlass"
import LaTorre from "../Projects/laTorre"
import Solar from "../Projects/Solar"
import Biofuels from "../Projects/Biofuels"
import Zacapa from "../Projects/Zacapa"
import PpysBBq from "../Projects/PpysBBq"
import Stoves from "../Projects/Stoves"
import Chair from "../Projects/Chair"
import BioSeed from "../Projects/BioSeed"
import Biodome from "../Projects/Biodome"
import Urtisan from "../Projects/Urtisan"
import MecanoCNC from "../Projects/MecanoCNC"
import Particles from "../Particles/index"
import latorre from "../../images/latorre.jpg"


function TechProjectsPage() {
  return (
    <div className={css.hexagon}>
      <div className={css.wrapper}>
        <div className={css.item1}>
          <br></br>
          <br></br>
          <div>
            <Particles />
          </div>

          <h2> Tech</h2>
          <br></br>
          <h2>Projects</h2>
        </div>
      </div>
      <br></br>
      <br></br>

      <section>
        <article>
          <figure>
            <h2>LoungeGlass</h2>
            <LoungeGlass />
          </figure>
          <img
            alt
            src={latorre}
          />
        </article>
        <article>
          <figure>
            <h2>LaTorre</h2>
            <laTorre />
          </figure>
          <img
            alt
            src={latorre}
          />
        </article>
        <article>
          <figure>
            <h2>Solar</h2>
            <Solar />
          </figure>
          <img
            alt
            src={latorre}
          />
        </article>
        <article>
          <figure>
            <h2>Biofuels</h2>
            <Biofuels />
          </figure>
          <img
            alt
            src={latorre}
          />
        </article>
        <article>
          <figure>
            <h2>Zacapa</h2>
            <Zacapa />
          </figure>
          <img
            alt
            src={latorre}
          />
        </article>
        <article>
          <figure>
            <h2>PpysBBq</h2>
            <PpysBBq />
          </figure>
          <img
            alt
            src={latorre}
          />
        </article>
        <article>
          <figure>
            <h2>Stoves</h2>
            <Stoves />
          </figure>
          <img
            alt
            src={latorre}
          />
        </article>
        <article>
          <figure>
            <h2>Chair</h2>
            <Chair />
          </figure>
          <img
            alt
            src={latorre}
          />
        </article>
        <article>
          <figure>
            <h2>BioSeed</h2>
            <BioSeed />
          </figure>
          <img
            alt
            src={latorre}
          />
        </article>
        <article>
          <figure>
            <h2>Biodome</h2>
            <Biodome />
          </figure>
          <img
            alt
            src={latorre}
          />
        </article>
        <article>
          <figure>
            <h2>Urtisan</h2>
            <Urtisan />
          </figure>
          <img
            alt
            src={latorre}
          />
        </article>
        <article>
          <figure>
            <h2>Mecano</h2>
            <MecanoCNC />
          </figure>
          <img
            alt
            src={latorre}
          />
        </article>
      </section>

      {/* <svg width="0" height="0">
        <defs>
          <clipPath id="hexagono" clipPathUnits="objectBoundingBox">
            <polygon points=".25 0, .75 0, 1 .5, .75 1, .25 1, 0 .5" />
          </clipPath>
        </defs>
      </svg> */}
    </div>
  )
}

export default TechProjectsPage

import React from "react"
import css from "./ManfacturePage.module.css"

function ManfacturePage() {
  return (
    <div class="manufacture">
      <p></p>
    </div>
  )
}

export default ManfacturePage
